<div
    style="height: 100vh; width: 100%; flex-direction: column; justify-content: center; align-items: center; background-color: #fff;padding: 10px; border-radius: 4px;">

    <div
        style="height: 90%; overflow-x: hidden; width: 98%; margin: 15px 0px; padding: 0px; border: 1px solid #ccc; display: flex; flex-direction: column; align-items: center; overflow-y: scroll;">

        <!-- <div style="display: flex; justify-content: space-between; width: 100%;">
            <div style="color: #323CB2; font-size: 12px; font-weight: bold;">Rx - Prescription Number : XXXXXX</div>
            <label style="color: #323CB2; font-size: 12px; font-weight: bold;">Date:<span
                    style="font-weight: 500;">{{todaysDate}}</span></label>
        </div> -->

        <!-- HEADER -->
        <div style="width:100%;  background-color:#F1F1F1; padding:10px 10px 0px 10px;">
            <div style="display: flex; justify-content: space-between;">
                <label style="color: #000; font-size:  16px; ">Name: <span style="font-weight: 600;">
                        {{fullName}} ({{ageObj.years}}y
                        {{ageObj.months}}m {{ageObj.days}}d)</span> </label>
                <label *ngIf="showOtherId==1" style=" width: 60%;"> <span
                        style="color: #000; font-weight: 600; font-size: 16px;">
                        <span style="width: 136px; display: inline-block;">
                            UHID /Ref No:
                        </span>
                    </span> <span style="font-size: 16px;">{{otherId}}</span></label>
            </div>
            <div style="display: flex; justify-content: space-between;">
                <!-- <label style="color: #000; font-weight: 600; font-size:  16px;">M: <span
                        style="font-weight: 500;font-size: 16px;">{{contactNo}}</span></label> -->
                <label style="background-color: #fff3cd;
                        color: #000;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 4px 4px 4px 0px;
                        height: fit-content;
                        border: 1px solid #856305;
                        border-radius: 4px;">Weight: <span style="font-weight: 600;">{{weight}} Kg</span></label>

<label style="color: #000; font-weight: 600; width: 70%;font-size:  16px; ">
    <span style="width: 136px; display: inline-block;" *ngIf="hideShowFlags.rxTabs['complaints']">Chief Complaints:</span>
  
    <span [attr.contenteditable]="editMode" (input)="onComplaintsInput($event)" class="inputStyle" style="min-width: 300px;"
    dir="ltr" [ngStyle]="{ border: editMode ? '1px solid #4686be' : 'none' }" #complaintsSpan *ngIf="hideShowFlags.rxTabs['complaints']">
  </span>
  </label>
            </div>

            <div style="display: flex; justify-content: space-between;">
                <!-- <div style="flex-direction:column; display:flex; background-color: red;"> -->
                <label style="color: #000; font-weight: 600; font-size:  16px;">Height: <span
                        style="font-weight: 500;">{{height}} Cms</span></label>

                <!-- <label style="color: #000; font-weight: 600; font-size:  16px;">Height: <span
                            style="font-weight: 500;">{{height}}</span></label> -->
                <!-- </div> -->

                <label *ngIf="hideShowFlags.rxTabs['onExaminations'] && !receptionistView" style="color: #000; font-weight: 600; width: 70%;font-size:  16px;">
                    <span style="width: 136px; display: inline-block;">
                        On Examination:
                    </span>
                    <span
  [attr.contenteditable]="editMode"
  (input)="onExaminationInput($event)"
  class="inputStyle"
  style="min-width:300px;"
  [ngStyle]="{ border: editMode ? '1px solid #4686be' : 'none' }"
  #onExaminationSpan>
</span>
</label>
            </div>
            <div style="display: flex; justify-content: space-between;">

                <label *ngIf="showAge" style="color: #000; font-weight: 600;font-size:  16px;">Birth-Weight: <span
                        style="font-weight: 500;">{{birthweight}} Kg</span></label>
                <label *ngIf="!showAge" style="color: #000; font-weight: 600;font-size:  16px;"> <span
                        style="font-weight: 500;"></span></label>

                <label *ngIf="hideShowFlags.rxTabs['diagnosis'] && !receptionistView" style="color: #000; font-weight: 600; width: 70%;font-size:  16px;">

                    <span style="width: 136px; display: inline-block;" >
                        Working Diagnosis:
                    </span>
                    <span
                    [attr.contenteditable]="editMode"
                    (input)="onDiagnosisInput($event)"
                    class="inputStyle"
                    style="min-width: 300px;"
                    [ngStyle]="{ border: editMode ? '1px solid #4686be' : 'none' }"
                    #diagnosisSpan
                    *ngIf="hideShowFlags.rxTabs['diagnosis']">
                  </span>
                  </label>

            </div>



            <!-- <div>
                <label style="color: #000; font-weight: bold; font-size: 15px;">Height: <span
                        style="font-weight: 500;">{{height}}</span></label>
            </div> -->
        </div>

        <!-- MEDICINES overflow-y: scroll; -->
        <div *ngIf="!receptionistView" cdkDropList (cdkDropListDropped)="drop($event)"
            style="border-bottom: 1px solid #ccc; width:100%; padding:0 16px 0 10px;">
            <div cdkDrag [cdkDragDisabled]="medicine.editing" *ngFor="let medicine of allMedicines; let i = index"
                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; cursor: pointer;">

                <div style="width: 85%;" *ngIf="medicine.selected || editMode">
                    <div style="font-weight: 600; margin-top: 8px; font-size: 18px; min-width: 150px;">
                        {{medicine.name}}
                    </div>

                    <div *ngIf="!editMode || !medicine.editing" style="font-size: 16px; min-width: 150px;">
                        {{medicine.dosage2}} {{medicine.whenToTake}}
                        {{medicine.duration2}}
                    </div>


                    <div *ngIf="!editMode || !medicine.editing" style="min-width: 150px;">{{medicine.instructions}}
                    </div>

                    <div style="display: flex; flex-direction: row;">
                        <div id="editableMedicineDiv" (input)="editMedicineText=$event.target.textContent"
                            data-placeholder="Enter medicine dosage" *ngIf="medicine.editing && editMode"
                            [textContent]="editMedicineText" onclick="event.stopPropagation();" contenteditable="true"
                            (keydown.enter)="$event.preventDefault()"
                            style="width: fit-content; border: 2px solid rgb(70, 134, 190); border-radius: 4px; padding: 3px; min-width: 150px;">


                        </div>

                        <div id="editableMedicineDivWhentotake"
                            (input)="editMedicineWhenToTake=$event.target.textContent"
                            data-placeholder="Enter Whentotake" *ngIf="medicine.editing && editMode"
                            [textContent]="editMedicineWhenToTake" onclick="event.stopPropagation();"
                            contenteditable="true" (keydown.enter)="$event.preventDefault()"
                            style="width: fit-content; border: 2px solid rgb(70, 134, 190); border-radius: 4px; padding: 3px; min-width: 150px;">


                        </div>


                        <div type="text" id="editableMedicineDivDuration" class="editable"
                            data-placeholder="Enter when to take"
                            (input)="editMedicineTextDuration=$event.target.textContent"
                            *ngIf="medicine.editing && editMode" [textContent]="editMedicineTextDuration"
                            onclick="event.stopPropagation();" contenteditable="true"
                            (keydown.enter)="$event.preventDefault()" data-maxlength="14"
                            style="width: fit-content; border: 2px solid rgb(70, 134, 190); border-radius: 4px; padding: 3px; min-width: 150px;">
                            {{medicine.duration2}}</div>
                    </div>

                    <!-- <div type="text" id="editableMedicineDivInstructions" *ngIf="medicine.editing && editMode"
                        class="editable" data-placeholder="Enter medicine instructions"
                        (input)="editMedicineTextInstructions=$event.target.textContent"
                        [textContent]="editMedicineTextInstructions" onclick="event.stopPropagation();"
                        contenteditable="true" (keydown.enter)="$event.preventDefault();"
                        style="width: fit-content; border: 2px solid rgb(70, 134, 190); border-radius: 4px; padding: 3px; min-width: 150px;">
                        {{medicine.instructions}}</div> -->

                    <div type="text" id="editableMedicineDivInstructions" *ngIf="medicine.editing && editMode "
                        class="editable" data-placeholder="Enter medicine instructions" style="margin: 20px 0;">


                        <app-language-select-textbox #langMedInstructions [width]="83"
                            [styles]="{'background-color':'#fff','height':'33px','border-radius':'4px'}" [stylesFor]="{'width': '80%','box-shadow':'none','padding': '3px', 'border-width': '1px',
                            'border-color': '#4686be',
                            'border-style': 'solid',
                    'border':'none','outline':'none','height':'39px!important' }" styleForWidth="instructions"
                            placeholder="" style="padding-left: 0px; width: 50%;"> </app-language-select-textbox>
                    </div>

                </div>

                <div style="margin: 0px 4px;">{{medicine.quantity}}</div>

                <div *ngIf="editMode" style="width: 13%;">
                   

                    <img (click)="editMedicineDose(i, $event)" *ngIf="!medicine.editing"
                        style="width:30px; margin: 0px 4px; color:#007d7e; cursor: pointer;"
                        src="assets/images/edit_icon.svg" class="largerCheckbox" />

                    <img (click)="openDialog(delete_modal, i)" *ngIf="!medicine.editing"
                        style="width:30px; margin: 0px 4px; color:#007d7e; cursor: pointer;"
                        src="assets/images/delete_icon_round.svg" class="largerCheckbox" />

                    <img (click)="updateMedicineDose(i, $event)" *ngIf="medicine.editing"
                        style="width:75px; color:#007d7e; cursor: pointer;" src="assets/images/update_tick_icon.svg"
                        class="largerCheckbox" />
                </div>
            </div>


            <!-- <div *ngIf="editMode" (click)="addAnotherMedicine()" style="text-align: center;
            font-weight: bold;
            color: #03237b;
            border: 1px solid #3204b1;
            border-radius: 6px;
            padding: 8px;
            margin-top: 16px;
            width: fit-content;
            cursor: pointer;
            ">&#9998; Edit/Change Prescription</div> -->


        </div>

        <!-- DIETARY ADVICE -->
        <div style="width: 100%; margin-top: 10px; margin-left: 20px;" *ngIf="!receptionistView">
            <label style="color: #000; font-weight: 600; font-size:  16px;" *ngIf="hideShowFlags.rxTabs['dietaryAdvice']">Dietary Advice:

                <app-language-select-textbox #langDietary [width]="100"
                    [styles]="{'width':'35vw','background-color':'#fff','height':'33px','border-radius':'4px'}"
                    [stylesFor]="{'width': '100%','box-shadow':'none','padding': '3px',
                'border':'none','outline':'none','height':'39px!important','border-width': '0px',
                'border-color': '#4686be',
                'border-style': 'solid' }" styleForWidth="instructions"
                    patientSelectedLanguage="{{patientSelectedLanguage}}" placeholder=""
                    style="padding-left: 0px; width: 50%;"  *ngIf="hideShowFlags.rxTabs['dietaryAdvice']"> </app-language-select-textbox>
                <!-- <span id="mru"
                    [attr.contenteditable]="editMode" [textContent]="selectedDietaryAdvices"
                    (input)="selectedDietaryAdvices=$event.target.textContent" class="inputStyle"
                    [ngStyle]="{border: editMode ? '1px solid #4686be' : 'null'}">
                 </span> -->
            </label>
        </div>
        <!-- vaccination Due -->
        <!-- 26 March start -->
        <div *ngIf="selectedVaccDueText" style="width: 100%; margin-top: 0px;margin-left: 20px;" >
            <label style="color: #000; font-weight: 600; font-size:  16px;" *ngIf="hideShowFlags.rxTabs['instruction'] && !receptionistView">Next Vaccination Due:
               
                    <app-language-select-textbox #langVaccDue [readonly]="true" [width]="100"
                    [styles]="{'width':'35vw','background-color':'#fff','height':'33px','border-radius':'4px'}"
                    [stylesFor]="{'width': '100%','box-shadow':'none','padding': '3px',
                    'border':'none','outline':'none','height':'39px!important','border-width': '1px',
                    'border-color': '#4686be', 'border-style': 'solid' }"
                    styleForWidth="instructions"
                    patientSelectedLanguage="{{patientSelectedLanguage}}" placeholder=""
                    style="padding-left: 0px; width: 50%;" > 
                </app-language-select-textbox>
            </label>
        </div>
        <!-- 26 March end -->
        <div style="width: 100%; margin-top: 0px;margin-left: 20px;">
            <label style="color: #000; font-weight: 600; font-size:  16px;" *ngIf="hideShowFlags.rxTabs['instruction'] && !receptionistView">Instructions:
                <!-- <span
                    [attr.contenteditable]="editMode" [textContent]="selectedGeneralInstructions"
                    (input)="selectedGeneralInstructions=$event.target.textContent" class="inputStyle"
                    [ngStyle]="{border: editMode ? '1px solid #4686be' : 'null'}">{{selectedGeneralInstructions}}</span> -->

                <app-language-select-textbox #langInstructions [width]="100"
                    [styles]="{'width':'35vw','background-color':'#fff','height':'33px','border-radius':'4px'}"
                    [stylesFor]="{'width': '100%','box-shadow':'none','padding': '3px',
                    'border':'none','outline':'none','height':'39px!important','border-width': '1px',
                    'border-color': '#4686be',
                    'border-style': 'solid' }" styleForWidth="instructions"
                    patientSelectedLanguage="{{patientSelectedLanguage}}" placeholder=""
                    style="padding-left: 0px; width: 50%;" *ngIf="hideShowFlags.rxTabs['instruction']"> </app-language-select-textbox>
            </label>
        </div>
        
        <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;" *ngIf="hideShowFlags.rxTabs['instruction'] && !receptionistView">
            <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5; margin: 4px;" #ref="matChip"
                *ngFor="let item of followUpMessageForLanguage;let i=index" (click)='selectFollowUpMessage(item);'
                [value]="item">
                {{item.value}}
            </mat-chip>

        </mat-chip-list>

        <!-- NEXT FOLLOWUP -->
        <!-- <div *ngIf="editMode" style="width: 100%; margin-top: 0px;">
            <label style="color: #000; font-weight: 600; font-size:  16px;">Next Followup: <span
                    [attr.contenteditable]="editMode" [textContent]="nextFollowUp"
                    (input)="nextFollowUp=$event.target.textContent" class="inputStyle"
                    [ngStyle]="{border: editMode ? '1px solid #4686be' : 'null'}">{{nextFollowUp}}</span></label>
        </div> -->


        <!-- SIGNATURE -->
        <!-- <div *ngIf="editMode"
            style="width: 100%; margin-bottom: 20px; display: flex; justify-content: flex-end; align-items: flex-end;">
            <label style="color: #000; font-weight: bold;">Dr. {{doctor.first_name}} {{doctor.last_name}} </label>
        </div> -->

        <div style="height: 200px;">
            <br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;

        </div>
    </div>

    <ng-template #delete_modal>
        <div style="width: 100%; padding: 20px;">
<h4>Delete Medicine?</h4>
        <div style="color: #555; font-size: 16px;">Are you sure you want to delete selected medicine?</div>
        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
            <button (click)="closeDialog()" style="background-color: #eee;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                      color: #444;
                      outline: none;">Cancel</button>

            <button (click)="deleteAction()" style="background-color: #f44336;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                outline: none;">Delete</button>
        </div>
        </div>
        
    </ng-template>


    <ng-template #pendingVaccination_modal>
        <div style="padding: 20px;">
            <div style="display: flex;flex-direction: row; justify-content: space-between;">
                <div style="font-size: 20px; font-weight: 600;">Select next vaccination due</div>
                <div>
                    <mat-form-field>
                        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                            (dateChange)="onDateChangeForVacc($event)" [(ngModel)]="vacDate" placeholder="Choose a date"
                            required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
    
                    </mat-form-field>
                </div>
            </div>
    
    
    
            <hr *ngIf="pendingVaccination.length">
            <h5 *ngIf="pendingVaccination.length" style="color:#36404D">Pending Vaccinations</h5>
            <div *ngIf="pendingVaccination.length" style="height: 30vh; overflow-y: scroll; scrollbar-width: auto;">
                    <ng-container [ngTemplateOutlet]="renderImmunization"
                        [ngTemplateOutletContext]="{value:pendingVaccination}">
                    </ng-container>
            </div>
    
            <ng-template #renderImmunization let-value="value">
    
    
                
    
                <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px; ">
                    <ng-container>
                        <!-- VISIT -->
                        <ng-container matColumnDef="visit">
                            <th mat-header-cell *matHeaderCellDef> Visit </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width:17%; font-weight: bold;">
                                <mat-checkbox class="example-margin" (change)="getVaccData(element)"></mat-checkbox>
                                {{element.visitName}}
    
                            </td>
                        </ng-container>
    
                        <!-- VACCINE -->
                        <ng-container matColumnDef="vaccine">
                            <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width: 17.5% !important;">
                                {{element.name}}
    
                            </td>
                        </ng-container>
    
                        <!-- RECOMMENDED DATE -->
                        <ng-container matColumnDef="recommendedDate">
                            <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;">
                                {{element.dueDate | date:"dd-MM-YYYY"}}
    
                            </td>
                        </ng-container>
    
                        <!-- CATCHUP DATE -->
                        <ng-container matColumnDef="catchupDate">
                            <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;">
                                {{element.catchupDate}}
                            </td>
                        </ng-container>
    
                        <!-- STATUS -->
    
    
                        <!-- RECEIVED DATE -->
    
    
                        <!-- BRAND -->
    
    
                        <!-- ACTION   -->
    
    
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;">
                    </tr>
                    <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"
                        *matRowDef="let row; columns: displayedColumns;" (click)="selectedRows(row)"></tr>
                </table>
            </ng-template>
    
    
    
    
            <hr>
            <h5 style="color:#36404D">Upcoming Vaccinations</h5>
    
    
            <div style="height: 30vh;  overflow-y: scroll; scrollbar-width: auto;">
                    <ng-container [ngTemplateOutlet]="renderImmunization"
                        [ngTemplateOutletContext]="{value:upcomingVaccineList}">
                    </ng-container>
            </div>
    
            <ng-template #renderImmunization let-value="value">
                <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
                    <ng-container>
                        <!-- VISIT -->
                        <ng-container matColumnDef="visit">
                            <th mat-header-cell *matHeaderCellDef> Visit </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width:17%; font-weight: bold;">
                                {{element.visitName}}
    
                            </td>
                        </ng-container>
    
                        <!-- VACCINE -->
                        <ng-container matColumnDef="vaccine">
                            <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width: 17.5% !important;"
                                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                                {{element.name}}
    
                            </td>
                        </ng-container>
    
                        <!-- RECOMMENDED DATE -->
                        <ng-container matColumnDef="recommendedDate">
                            <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                                {{element.dueDate | date:"dd-MM-YYYY"}}
    
                            </td>
                        </ng-container>
    
                        <!-- CATCHUP DATE -->
                        <ng-container matColumnDef="catchupDate">
                            <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                                {{element.catchupDate}}
                            </td>
                        </ng-container>
    
    
                        <!-- STATUS -->
    
    
                        <!-- RECEIVED DATE -->
    
    
                        <!-- BRAND -->
    
    
                        <!-- ACTION   -->
    
    
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-template>
            <!-- <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 55vw; height: 30vh; overflow: scroll;">
                    <div *ngFor="let item of upcomingVaccineList; let i = index" (click)="selectModalValue(i)"
                    class="searchable-component-style" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
                        <img *ngIf="item.selected != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                        src="assets/images/tick_mark_grey.svg" class="tick-icon" />
          
                      <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                        src="assets/images/tick_mark_color.svg" class="tick-icon" />
                      <div style="font-size: 16px;">{{item.name}}</div><br>
                    
                    </div>
                    </div> -->
            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" style="background-color: #eee;
                font-size: 16px;
                          padding: 8px 16px;
                          border-radius: 6px;
                          color: #fff;
                          font-weight: bold;
                          border: 0px;
                          margin: 4px;
                          color: #444;
                          outline: none;">Cancel</button>
    
                <button (click)="savePendingVaccination();closeDialog();" style="background-color: #5D86CC;
                font-size: 16px;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                    outline: none;">Add Vaccinations</button>
            </div>
        </div>
    </ng-template>

</div>